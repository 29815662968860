import { useQuery } from "@tanstack/react-query"
import { endpoints } from "api"

export const getQueryKey = (id?: string) =>
  ["trials", id, "treatments"] as const

function useTrialTreatments(
  id?: string,
  options?: Partial<{
    onSuccess: (id?: string) => void
  }>
) {
  const endpoint = endpoints.campaigns.trials.treatments(id)

  return useQuery(getQueryKey(id), endpoint.get, {
    select: (data) => data.data.data,
    enabled: Boolean(id),
    onSuccess: () => options?.onSuccess?.(),
  })
}

useTrialTreatments.getQueryKey = getQueryKey

export default useTrialTreatments
