import type { GridEnrichedColDef, GridValidRowModel } from "@mui/x-data-grid"
import { gridStringOrNumberComparator } from "@mui/x-data-grid"
import RemoveIcon from "@mui/icons-material/Remove"
import { Link } from "@mui/material"
import { Link as RouterLink } from "react-router-dom"
import assert from "./assert"
import { parseDate, formatDate, DATE_FORMATS } from "./date"
import { formatToCurrency } from "./formatToCurrency"

export function dateTimeColumnDefaultOptions<T extends GridValidRowModel>({
  onlyDate,
}: {
  onlyDate?: boolean
} = {}): Pick<
  GridEnrichedColDef<T>,
  | "valueParser"
  | "valueFormatter"
  | "renderCell"
  | "getApplyQuickFilterFn"
  | "type"
> {
  return {
    type: onlyDate ? "date" : "dateTime",
    getApplyQuickFilterFn: (searchString: string) => {
      if (!searchString) {
        return null
      }

      return ({ formattedValue }): boolean => {
        return formattedValue?.includes(searchString)
      }
    },
    valueParser: ({ value }) => parseDate(value),
    valueFormatter: ({ value }) =>
      formatDate(
        value,
        onlyDate
          ? DATE_FORMATS.standardShortDate
          : DATE_FORMATS.standardShortDateTime
      ),
    renderCell: ({ formattedValue }) =>
      formattedValue || <RemoveIcon color="disabled" fontSize="small" />,
  }
}

export function currencyColumnDefaultOptions<T extends GridValidRowModel>({
  isPence,
  valueKey,
  getValue,
  currencyKey,
  currency,
}: {
  isPence?: boolean
  valueKey?: string
  getValue?: (row: T) => number
  currencyKey?: string
  currency?: string
}): Pick<
  GridEnrichedColDef<T>,
  | "renderCell"
  | "type"
  | "getApplyQuickFilterFn"
  | "valueGetter"
  | "valueFormatter"
  | "sortComparator"
  | "filterOperators"
  | "editable"
  | "renderEditCell"
> {
  return {
    type: "number",
    editable: false,
    renderEditCell: () => {
      throw new Error("Editable currency cells are not implemented")
    },
    valueGetter: ({ value, row }) => {
      const amount = valueKey ? row[valueKey] : getValue ? getValue(row) : value
      const currencyType = currencyKey ? row[currencyKey] : currency

      return [amount, currencyType]
    },
    sortComparator: ([v1], [v2], params1, params2) => {
      return gridStringOrNumberComparator(v1, v2, params1, params2)
    },
    filterOperators: [],
    valueFormatter: ({ value: [amount, currencyType] }) => {
      assert(typeof amount === "number")

      return formatToCurrency(amount, currencyType || undefined, isPence)
    },
    getApplyQuickFilterFn: (searchValue) => {
      return ({ value: [amount] }) => {
        return amount && (amount / 100).toFixed(2).includes(searchValue)
      }
    },
    renderCell: ({ formattedValue }) => {
      return formattedValue || <RemoveIcon color="disabled" fontSize="small" />
    },
  }
}

export function linkColumnDefaultOptions<T extends GridValidRowModel>({
  getDisplayValue,
  getHref,
}: {
  getDisplayValue?: (row: T) => string
  getHref: (row: T) => string
}): Pick<GridEnrichedColDef<T>, "renderCell"> {
  return {
    renderCell: ({ formattedValue, row }) => (
      <Link
        component={RouterLink}
        to={getHref(row)}
        sx={{ lineBreak: "anywhere" }}
      >
        {getDisplayValue?.(row) || formattedValue}
      </Link>
    ),
  }
}

export function nullableColumnDefaultOptions<
  T extends GridValidRowModel
>(): Pick<GridEnrichedColDef<T>, "renderCell"> {
  return {
    renderCell: ({ value }) => {
      return value ? (
        <span style={{ lineBreak: "anywhere" }}>{value}</span>
      ) : (
        <RemoveIcon color="disabled" fontSize="small" />
      )
    },
  }
}
