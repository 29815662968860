import { Grid, Button, Box, TextField, Alert } from "@mui/material"
import { CollapsibleCard, SimpleGrid } from "components"
import type { GridColumns } from "@mui/x-data-grid"
import { useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import JsonPopout from "components/JsonPopout/JsonPopout"
import type { GridRowId } from "@mui/x-data-grid"
import useCaisReportDetail from "./hooks/useCaisReportDetail"
import useCaisReportsUpdateNote from "./hooks/useCaisReportItemsUpdateNote"
import useCaisReportItemsDelete from "./hooks/useCaisReportItemsDelete"
import useCaisReportVerify from "./hooks/useCaisReportVerify"
export default function CaisReport() {
  const { id: cra_report_id } = useParams()
  const { data: caisReport, isLoading: isLoadingCaisReport } =
    useCaisReportDetail(cra_report_id)
  const { mutate: updateNote, isLoading: isUpdatingNote } =
    useCaisReportsUpdateNote()
  const { mutate: deleteReportItems, isLoading: isDeletingReportItems } =
    useCaisReportItemsDelete()
  const { mutate: verifyReport, isLoading: isVerifyingReport } =
    useCaisReportVerify()

  const [selectedRows, setSelectedRows] = useState<GridRowId[]>([])
  const [noteText, setNoteText] = useState("")

  const columns: GridColumns = useMemo(
    () => [
      {
        field: "cra_account_code",
        headerName: "Account Number",
        width: 120,
      },
      {
        field: "dob",
        headerName: "Date of Birth",
        width: 100,
      },
      {
        field: "start_date",
        headerName: "Start Date",
        width: 90,
      },
      {
        field: "close_date",
        headerName: "Close Date",
        width: 90,
        valueFormatter: (params) => params.value || "N/A",
      },
      {
        field: "status_code",
        headerName: "Status Code",
        width: 90,
        align: "right",
      },
      {
        field: "historical_status_codes",
        headerName: "Historical Status Codes",
        width: 160,
        renderCell: (params) => {
          if (!params.value || !Array.isArray(params.value)) return ""
          return (
            <div style={{ whiteSpace: "pre-line" }}>
              {params.value
                .map(
                  (item) =>
                    `${item.generated_at_utc.split(" ")[0]}: ${
                      item.status_code
                    }`
                )
                .join("\n")}
            </div>
          )
        },
      },
      {
        field: "account_type",
        headerName: "Account Type",
        width: 100,
        align: "right",
      },
      {
        field: "credit_limit",
        headerName: "Credit Limit",
        width: 90,
        headerAlign: "left",
        type: "number",
      },
      {
        field: "flag_setting",
        headerName: "Flag Setting",
        width: 90,
      },
      {
        field: "payment_code",
        headerName: "Payment Code",
        width: 110,
      },
      {
        field: "payment_freq",
        headerName: "Payment Frequency",
        width: 140,
      },
      {
        field: "new_account_nr",
        headerName: "New Account Number",
        width: 150,
      },
      {
        field: "payment_amount",
        headerName: "Payment Amount",
        width: 120,
        headerAlign: "left",
        type: "number",
      },
      {
        field: "current_balance",
        headerName: "Current Balance",
        width: 120,
        headerAlign: "left",
        type: "number",
      },
      {
        field: "monthly_payment",
        headerName: "Monthly Payment",
        width: 120,
        headerAlign: "left",
        type: "number",
      },
      {
        field: "cash_advances_nr",
        headerName: "Cash Advances Number",
        width: 160,
        headerAlign: "left",
        type: "number",
      },
      {
        field: "name_and_address",
        headerName: "Name and Address",
        width: 200,
      },
      {
        field: "previous_balance",
        headerName: "Previous Balance",
        width: 120,
        headerAlign: "left",
        type: "number",
      },
      {
        field: "repayment_period",
        headerName: "Repayment Period",
        width: 130,
        headerAlign: "left",
        type: "number",
      },
      {
        field: "promotion_activity",
        headerName: "Promotion Activity",
        width: 130,
      },
      {
        field: "cash_advances_value",
        headerName: "Cash Advances Value",
        width: 150,
        headerAlign: "left",
        type: "number",
      },
      {
        field: "special_instruction",
        headerName: "Special Instruction",
        width: 130,
      },
      {
        field: "original_default_balance",
        headerName: "Original Default Balance",
        width: 160,
        headerAlign: "left",
        type: "number",
      },
      {
        field: "default_satisfaction_date",
        headerName: "Default Satisfaction Date",
        width: 170,
        valueFormatter: (params) => params.value || "N/A",
      },
      {
        field: "transferred_to_collection_account",
        headerName: "Transferred to Collection",
        width: 170,
        valueGetter: (params) => Boolean(params.value),
        type: "boolean",
      },
      {
        field: "note",
        headerName: "Note",
        width: 80,
        valueGetter: (params) => params.row.cra_report_item_properties?.note,
      },
    ],
    []
  )

  const checkResultColumns: GridColumns = useMemo(
    () => [
      {
        field: "check",
        headerName: "Check Name",
        width: 200,
        sortable: false,
      },
      {
        field: "value",
        headerName: "Result",
        flex: 1,
        sortable: false,
      },
    ],
    []
  )

  const transformedCheckResults = useMemo(() => {
    if (!caisReport?.cra_report_properties) return []

    const otherEntries = Object.entries(caisReport.cra_report_properties)
      .filter(([key]) => key !== "overall_result")
      .map(([key, value]) => ({
        id: key,
        check: key.replace(/_/g, " ").replace(/\b\w/g, (c) => c.toUpperCase()),
        value:
          typeof value === "object"
            ? (Array.isArray(value) && value.length === 0) ||
              Object.keys(value).length === 0
              ? 0
              : JSON.stringify(value, null, 2)
            : value?.toString() || "N/A",
      }))

    const overallRow = {
      id: "overall_result",
      check: "Overall Result",
      value: caisReport.cra_report_properties.overall_result || "N/A",
    }

    return [overallRow, ...otherEntries]
  }, [caisReport?.cra_report_properties])

  const getSelectedAccountCodes = (selectedRows: GridRowId[]) => {
    return selectedRows
      .map(
        (rowId) =>
          caisReport?.cra_report_items.find(
            (item) => item.cra_account_code === rowId
          )?.cra_account_code
      )
      .filter(Boolean) as string[]
  }

  const onUpdateNote = () => {
    if (!cra_report_id || selectedRows.length === 0 || !noteText.trim()) {
      return
    }

    updateNote({
      cra_report_id,
      cra_account_codes: getSelectedAccountCodes(selectedRows),
      note: noteText.trim(),
    })
    setNoteText("")
  }

  const onDeleteReportItems = () => {
    if (!cra_report_id || selectedRows.length === 0) {
      return
    }

    if (
      !window.confirm(
        `Are you sure you want to delete ${selectedRows.length} selected report item(s)?`
      )
    ) {
      return
    }

    deleteReportItems({
      cra_report_id,
      cra_account_codes: getSelectedAccountCodes(selectedRows),
    })
  }

  return (
    <Grid item xs={16}>
      <CollapsibleCard title="CAIS Automated Checks" type="main">
        {!!caisReport?.cra_report_properties?.deleted_cra_account_codes
          ?.length && (
          <Alert severity="warning" sx={{ mb: 2 }}>
            {caisReport.cra_report_properties.deleted_cra_account_codes.length}{" "}
            account code(s) have been deleted from this report.
          </Alert>
        )}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SimpleGrid
              columns={checkResultColumns}
              rows={transformedCheckResults}
              isLoading={isLoadingCaisReport}
              idField="id"
            />
            <Box display="flex" gap={2} mt={2} justifyContent="flex-end">
              <JsonPopout
                text="Show Full Results"
                title="Check Results"
                btnColor="primary"
                btnVariant="contained"
                json={caisReport?.cra_report_properties || {}}
              />
              <Button
                variant="contained"
                color="success"
                onClick={() => {
                  verifyReport({ cra_report_id })
                }}
                disabled={
                  caisReport?.cra_report_items?.[0]?.verified_at_utc != null ||
                  isVerifyingReport
                }
                sx={{ whiteSpace: "nowrap", height: "40px" }}
              >
                Verify Report
              </Button>
            </Box>
          </Grid>
        </Grid>
      </CollapsibleCard>
      <CollapsibleCard title="CAIS Report Rows" type="main">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SimpleGrid
              columns={columns}
              rows={caisReport?.cra_report_items || []}
              idField="cra_account_code"
              isLoading={isLoadingCaisReport}
              quickFilter
              disableColumnFilter={false}
              disableColumnSelection={false}
              checkboxSelection
              selectionModel={selectedRows}
              onSelectionModelChange={(newSelectionModel) => {
                setSelectedRows(newSelectionModel)
              }}
              initialState={{
                sorting: {
                  sortModel: [{ field: "cra_account_code", sort: "asc" }],
                },
              }}
              pagination
              initialPaginationPageSize={20}
              rowsPerPageOptions={[10, 20, 50, 100]}
              sx={{
                "& .MuiDataGrid-root": {
                  overflow: "visible",
                },
                "& .MuiDataGrid-cell": {
                  overflow: "visible",
                },
                "& .MuiDataGrid-row": {
                  overflow: "visible",
                },
                "& .MuiDataGrid-columnHeader": {
                  overflow: "visible",
                },
              }}
            />
            <Box display="flex" gap={2} mt={2}>
              <TextField
                fullWidth
                value={noteText}
                onChange={(e) => setNoteText(e.target.value)}
                placeholder="Enter note"
                size="small"
              />
              <Button
                variant="contained"
                onClick={onUpdateNote}
                disabled={selectedRows.length === 0 || isUpdatingNote}
                sx={{ whiteSpace: "nowrap", width: "100px" }}
              >
                Add Note
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={onDeleteReportItems}
                disabled={selectedRows.length === 0 || isDeletingReportItems}
                sx={{ whiteSpace: "nowrap", width: "150px" }}
              >
                Delete Report Rows
              </Button>
            </Box>
          </Grid>
        </Grid>
      </CollapsibleCard>
    </Grid>
  )
}
