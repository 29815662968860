import { Page } from "components/Page/Page"
import { TabNav } from "components"
import { Outlet, useLocation } from "react-router-dom"
import { routes } from "routes"

export const ClientBookMonitoring = () => {
  const { pathname } = useLocation()

  const TABS = [
    { label: "Strategic Review", href: routes.strategicReviewAll() },
  ]

  return (
    <Page
      title="Client Book Monitoring"
      headerTitle="Client Book Monitoring"
      headerSubtitle="Monitor your client book with the help of AI"
      tabs={<TabNav tabs={TABS} currentTab={pathname} />}
      scrollToTopButton
    >
      <Outlet />
    </Page>
  )
}
