import { useMutation, useQueryClient } from "@tanstack/react-query"
import { endpoints } from "api"
import type { AxiosError } from "axios"
import { useNotification } from "context/notification"
import { getQueryKey } from "./useCaisReportDetail"

export default function useCaisReportItemsDelete() {
  const notify = useNotification()
  const queryClient = useQueryClient()

  return useMutation(
    ({
      cra_report_id,
      cra_account_codes,
    }: {
      cra_report_id: string
      cra_account_codes: string[]
    }) =>
      endpoints.caisReports
        .details(cra_report_id)
        .delete({ cra_account_codes }),
    {
      onSuccess: (_, { cra_report_id }) => {
        notify.info("Report rows deleted successfully")
        return queryClient.invalidateQueries(getQueryKey(cra_report_id))
      },
      onError: (e: AxiosError) => {
        const data = e?.response?.data as { message: string }
        notify.error(`Error: ${data?.message}`)
      },
    }
  )
}
