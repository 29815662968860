import { useMutation } from "@tanstack/react-query"
import { endpoints } from "api"

function usePostLinkedCampaigns(
  options?: Partial<{
    onSuccess: (id?: string) => void
    onError: (id?: string) => void
  }>
) {
  const endpoint = endpoints.multimediaCampaigns.link_campaigns()

  return useMutation(
    (payload: {
      experiment_id: string
      treatments: Array<{
        treatment_id: string
        multimedia_campaign_id: string
      }>
    }) => {
      const processedPayload = {
        ...payload,
        treatments: payload.treatments.map((treatment) => ({
          ...treatment,
          multimedia_campaign_id: treatment.multimedia_campaign_id,
        })),
      }
      return endpoint.post(processedPayload)
    },
    {
      onSuccess: () => {
        options?.onSuccess?.()
      },
      onError: () => {
        options?.onError?.()
      },
    }
  )
}

export default usePostLinkedCampaigns
