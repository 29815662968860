import { BUILD_REGION, getBuildRegion } from "shell/config"
import type { SUPPORTED_DATA_SOURCES } from "./supportedDataSources"

export const FILTER_OPTIONS: {
  id: string
  name: string
  description: string
  tables: string[]
  where: string
  types: string[]
  prepend?: string
  append?: string
  data?:
    | {
        source: typeof SUPPORTED_DATA_SOURCES[number]
        key: string
        display: string
      }
    | {
        source: string
      }
    | string[]
}[] = [
  {
    id: "company_id",
    name: "Company",
    description: "Limit to company",
    tables: [],
    where: "company_id = %(company_id)s",
    types: ["list"],
    data: {
      source: "companies",
      key: "company_id",
      display: "name",
    },
  },
  {
    id: "company_ids",
    name: "Multiple Companies",
    description: "Limit to one or more companies",
    tables: [],
    where: "company_id::text = ANY(%(company_ids)s)",
    types: ["multi"],
    data: {
      source: "companies",
      key: "company_id",
      display: "name",
    },
  },
  {
    id: "company_ids_not",
    name: "Exclude Companies",
    description: "Exclude companies from the list",
    tables: [],
    where: "NOT (company_id::text = ANY(%(company_ids_not)s))",
    types: ["multi"],
    data: {
      source: "companies",
      key: "company_id",
      display: "name",
    },
  },
  {
    id: "company_partner_state",
    name: "Company partner state",
    description: "What company partner state is allowed",
    tables: ["companies"],
    types: ["multi"],
    where: "companies.partner_state::text = ANY(%(company_partner_state)s)",
    data: ["DISABLED", "TRIAL", "ONBOARDING", "LIVE"],
  },
  {
    id: "transfers_total_num",
    name: "Number of transfers",
    description: "Total number of transfers",
    tables: ["balance_history"],
    where: "balance_history->>'total_num_transfers' = %(transfers_total_num)s",
    types: ["comparison"],
  },
  {
    id: "salary_or_hourly",
    name: "Salary or hourly",
    description: "Salary or hourly employee",
    tables: [],
    where:
      "employees.salary_properties->>'salary_or_hourly' = %(salary_or_hourly)s",
    types: ["list"],
    data: ["salary", "hourly"],
  },
  {
    id: "employee_ids",
    name: "Employees",
    description: "Limit to a set of employees (comma separated)",
    tables: ["employees"],
    where:
      "employees.employee_id::text = ANY(string_to_array(%(employee_ids)s, ','))",
    types: ["string-equals"],
    prepend: "=",
  },
  {
    id: "employee_status",
    name: "Employee status",
    description: "The status of the employee",
    tables: [],
    where: "employees.current_state::text = ANY(%(employee_status)s)",
    types: ["multi"],
    data: ["ENROLLED", "ACTIVE", "INSTALLED", "PENDING", "NEW"],
  },
  {
    id: "enrollment_days_min",
    name: "Enrolled for min days",
    description: "Has been enrolled for >X days",
    tables: ["enrollment_history"],
    where:
      "enrollment_history.created_at + (%(enrollment_days_min)s || ' days')::interval < %(now)s",
    types: ["comparison"],
    append: "days",
  },
  {
    id: "enrollment_days_max",
    name: "Enrolled for max days",
    description: "Has been enrolled for <X days",
    tables: ["enrollment_history"],
    where:
      "enrollment_history.created_at + (%(enrollment_days_max)s || ' days')::interval >= %(now)s",
    types: ["comparison"],
    append: "days",
  },
  {
    id: "created_days_min",
    name: "Created for min days",
    description: "Has been created for >X days",
    tables: ["employees"],
    where:
      "employees.created_at + (%(created_days_min)s || ' days')::interval < %(now)s",
    types: ["comparison"],
    append: "days",
  },
  {
    id: "created_days_max",
    name: "Created for max days",
    description: "Has been created for <X days",
    tables: ["employees"],
    where:
      "employees.created_at + (%(created_days_max)s || ' days')::interval >= %(now)s",
    types: ["comparison"],
    append: "days",
  },
  {
    id: "viewed_dialog",
    name: "Viewed dialog",
    description: "Viewed dialog X days ago",
    tables: ["employee_in_app_dialogs"],
    where:
      "employee_id in (select employee_id from employee_in_app_dialogs where in_app_dialog_id = %(viewed_dialog)s and (employee_id = %(employee_id)s or %(employee_id)s is NULL) and viewed_at + (%(viewed_dialog_1)s || 'days')::interval < %(now)s)",
    types: ["list", "comparison"],
    data: {
      source: "in_app_dialogs",
      key: "in_app_dialog_id",
      display: "title",
    },
    append: "days ago",
  },
  {
    id: "show_after",
    name: "Only show after date",
    description: "Enable segment after X",
    tables: [],
    where: "%(now)s >= %(show_after)s",
    types: ["date"],
  },
  {
    id: "show_until",
    name: "Only show until date",
    description: "Enable segment until X",
    tables: [],
    where: "%(now)s < %(show_until)s",
    types: ["date"],
  },
  {
    id: "app_feature",
    name: "Has app feature",
    description: "",
    tables: ["companies"],
    types: ["list"],
    where:
      "(companies.properties->'app_features')::jsonb->%(app_feature)s = 'true'",
    data: {
      source: "app_features",
      key: "app_feature_id",
      display: "name",
    },
  },
  {
    id: "has_savings_account",
    name: "Has Savings account",
    description: "Has Savings account",
    tables: ["savings_accounts"],
    where:
      "employee_id IN (select employee_id from savings_accounts where employee_id = %(employee_id)s or %(employee_id)s is NULL)",
    types: ["boolean"],
  },
  {
    id: "no_savings_account",
    name: "No Savings account",
    description: "No Savings account",
    tables: ["savings_accounts"],
    where:
      "employee_id NOT IN (select employee_id from savings_accounts where employee_id = %(employee_id)s or %(employee_id)s is NULL)",
    types: ["boolean"],
  },
  {
    id: "has_open_banking",
    name: "Connected Open Banking",
    description: "Connected open banking",
    tables: ["user_auth_tokens"],
    where:
      "user_id in (select user_id from user_auth_tokens join user_roles using (user_id) where properties->>'provider' = 'truelayer' and deactivated_at is null and expires_at > %(now)s and (employee_id = %(employee_id)s or %(employee_id)s is NULL))",
    types: ["boolean"],
  },
  {
    id: "no_open_banking",
    name: "Not Connected Open Banking",
    description: "Hasn't connected open banking",
    tables: ["user_auth_tokens"],
    where:
      "user_id not in (select user_id from user_auth_tokens join user_roles using (user_id) where properties->>'provider' = 'truelayer' and deactivated_at is null and expires_at > %(now)s and (employee_id = %(employee_id)s or %(employee_id)s is NULL))",
    types: ["boolean"],
  },
  {
    id: "has_payment_reminders",
    name: "Payment Reminder(s)",
    description: "Has at least 1 payment reminder",
    tables: ["user_payment_reminders"],
    where:
      "user_id in (select user_id from user_payment_reminders join user_roles using (user_id) where user_payment_reminders.deleted_at is null and (employee_id = %(employee_id)s or %(employee_id)s is NULL))",
    types: ["boolean"],
  },
  {
    id: "no_payment_reminders",
    name: "No Payment Reminders",
    description: "Has no payment reminders",
    tables: ["user_payment_reminders"],
    where:
      "user_id not in (select user_id from user_payment_reminders join user_roles using (user_id) where user_payment_reminders.deleted_at is null and (employee_id = %(employee_id)s or %(employee_id)s is NULL))",
    types: ["boolean"],
  },
  {
    id: "payment_country_code",
    name: "Payment country code",
    description:
      "Which countries do you want to target (for ALL, simply remove this filter)",
    tables: ["companies"],
    where:
      "companies.properties->>'payment_country_code' = ANY(%(payment_country_code)s)",
    types: ["multi"],
    data: ["GB", "IE", "ES"],
  },
  {
    id: "language_code",
    name: "Language code",
    description: "",
    tables: ["companies"],
    where: "employees.properties->>'language_code' = %(language_code)s",
    types: ["list"],
    data: ["EN-EN", "ES-ES", "NL-NL", "EN-NL", "EN-US"],
  },
  {
    id: "transferred_since_days",
    name: "Transferred within",
    description: "Users who have transferred within the past X days",
    tables: ["last_transfer"],
    where:
      "last_transfer.created_at > %(now)s - (%(transferred_since_days)s || 'days')::interval",
    types: ["comparison"],
    append: "days",
  },
  {
    id: "not_transferred_since_days",
    name: "Not transferred for",
    description: "Users who have not transferred within the past X days",
    tables: ["last_transfer"],
    where:
      "last_transfer.created_at < %(now)s - (%(not_transferred_since_days)s || 'days')::interval",
    types: ["comparison"],
    append: "days",
  },
  {
    id: "salary_amount",
    name: "Salary",
    description: "Salary greater than",
    tables: ["employees"],
    where:
      "(coalesce(salary_properties->>'salary','0'))::decimal >= %(salary_amount)s",
    types: ["comparison"],
    prepend: "&gt; &pound;",
  },
  {
    id: "integration_partner",
    name: "Integration Partner",
    description: "E.g. Fourth",
    tables: ["companies", "company_integrations"],
    where: "company_integrations.partner = %(integration_partner)s",
    types: ["list"],
    data: {
      source: "integrations",
    },
  },
  {
    id: "sent_email",
    name: "Received e-mail",
    description: "Has been sent a particular e-mail at least X days ago",
    tables: ["email_logs"],
    where:
      "template_id = %(sent_email)s and logged_at + (%(sent_email_1)s || 'days')::interval < %(now)s",
    types: ["list", "comparison"],
    append: "days ago",
    prepend: "&gt;",
    data: {
      source: "email_templates",
      key: "email_template_id",
      display: "template_id",
    },
  },
  {
    id: "not_sent_email",
    name: "Not received e-mail",
    description:
      "Has not been sent a particular e-mail, at all, (if X unset) or not within X days",
    tables: ["email_logs"],
    where:
      "NOT EXISTS (select 1 from email_logs where template_id = ANY(string_to_array(%(not_sent_email)s, ',')) and user_id = user_roles.user_id and (COALESCE(%(not_sent_email_1)s, '')::text = '' or logged_at + (%(not_sent_email_1)s || 'days')::interval >= %(now)s))",
    types: ["string-equals", "comparison"],
    append: "days ago",
    prepend: "&gt;",
  },
  {
    id: "invite_date_filter",
    name: "Invitation date filter",
    description:
      "Include only employees who have never been invited or were invited before a given date",
    tables: ["employees"],
    where:
      "employees.invited_at IS NULL OR employees.invited_at < %(invite_date_filter)s",
    types: ["date"],
  },
  {
    id: "company_invite_on_creation",
    name: "Company invite on creation enabled",
    description: "Company has invite on creation enabled",
    tables: ["companies"],
    where:
      "COALESCE(companies.properties->>'invite_on_creation', 'false')::boolean = true",
    types: ["boolean"],
  },
  {
    id: "furloughed",
    name: "Furloughed",
    description: "Furloughed employees",
    tables: [""],
    where:
      "employees.properties->>'furloughed' IN (select unnest(string_to_array(%(furloughed)s,',')))",
    types: ["list"],
    data: ["UNFURLOUGHED", "INTEGRATION", "DIRECT", "DIRECT,INTEGRATION"],
  },
]

// Define US_ONLY_FILTERS as an array
const US_ONLY_FILTERS = [
  {
    id: "partnership",
    name: "Partnership",
    description: "Filter by partnership",
    tables: ["companies"],
    where:
      "COALESCE(companies.properties->>'partnership', '') IN (select unnest(string_to_array(%(partnership)s,',')))",
    types: ["list"],
    data: ["osv", "wagestream", "harri", "lip", "frontline"],
  },
  {
    id: "filter_by_exclude_created_before",
    name: "Use launch_comms_series_exclude_created_before filter",
    description: "Filter by company-set exclude created before date",
    tables: ["employees", "companies"],
    where:
      "COALESCE(companies.properties ->> 'launch_comms_series_exclude_created_before', '1990-01-01')::TIMESTAMP < employees.created_at",
    types: ["boolean"],
  },
]

// Then push all US-only filters at once
if (getBuildRegion() === BUILD_REGION.US) {
  FILTER_OPTIONS.push(...US_ONLY_FILTERS)
}
