import { zonedTimeToUtc } from "date-fns-tz"
import assert from "../../../utils/assert"
import type { SegmentBuilderFormValuesFilter } from "../SegmentBuilderDialog"

export function formatDateValuesInFilter({
  id,
  types,
  values,
  ...rest
}: SegmentBuilderFormValuesFilter) {
  return {
    id,
    types,
    ...rest,
    values: values.map((value): string | boolean | string[] => {
      if (value instanceof Date) {
        const formattedDate = zonedTimeToUtc(value, "UTC").toISOString()
        assert(formattedDate)
        return formattedDate
      }
      return value
    }),
  }
}
