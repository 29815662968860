import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material"
import { LoadingButton } from "@mui/lab"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { Controller, useForm } from "react-hook-form"
import { formatDate, parseDate } from "utils/date"
import { zonedTimeToUtc } from "date-fns-tz"
import { DateTimePicker } from "@mui/x-date-pickers"
import { BUILD_REGION, getBuildRegion } from "shell/config"
import assert from "../../../../utils/assert"

interface EditDialogProps {
  defaultValues: {
    pay_period_start: Date | null
    paid_on: Date | null
    blackout_period_start: Date | null
    lock_date: Date | null
  }
  errorMessage?: string
  isSubmitting: boolean
  onSubmit: (values: {
    pay_period_start: string
    paid_on: string
    blackout_period_start?: string
    lock_date?: string
  }) => void
  onClose: () => void
}

function formatDateForSubmission(date: Date) {
  return formatDate(zonedTimeToUtc(date, "UTC"), "yyyy-MM-dd")
}

function formatDateTimeForSubmssion(date: Date) {
  return formatDate(date, "yyyy-MM-dd HH:mm:ssxxx")
}

function getTimezoneOffset(value: Date) {
  return value.getTimezoneOffset() * 60000
}

function makeLocalAppearUTC(dateTime: Date) {
  return new Date(dateTime.getTime() + getTimezoneOffset(dateTime))
}

function localToUTC(dateTime: Date) {
  return new Date(dateTime.getTime() - getTimezoneOffset(dateTime))
}

export function EditDialog({
  defaultValues,
  isSubmitting,
  errorMessage,
  onSubmit,
  onClose,
}: EditDialogProps) {
  const { control, handleSubmit } = useForm({
    defaultValues,
  })

  const handleFormSubmit = handleSubmit(
    ({ pay_period_start, paid_on, blackout_period_start, lock_date }) => {
      assert(pay_period_start)
      assert(paid_on)

      const formattedPayPeriodStart = formatDateForSubmission(pay_period_start)
      const formattedPaidOn = formatDateForSubmission(paid_on)
      const formattedBlackoutPeriodStart = blackout_period_start
        ? formatDateForSubmission(blackout_period_start)
        : undefined
      const formattedLockDate = lock_date
        ? formatDateTimeForSubmssion(lock_date)
        : undefined

      assert(formattedPayPeriodStart)
      assert(formattedPaidOn)
      if (blackout_period_start) {
        assert(formattedBlackoutPeriodStart)
      }

      onSubmit({
        pay_period_start: formattedPayPeriodStart,
        paid_on: formattedPaidOn,
        blackout_period_start: formattedBlackoutPeriodStart ?? undefined,
        lock_date: formattedLockDate ?? undefined,
      })
    }
  )

  return (
    <Dialog open={true} onClose={onClose}>
      <form name="edit-pay-schedule-date" onSubmit={handleFormSubmit}>
        <DialogTitle>Edit pay schedule date</DialogTitle>
        <DialogContent>
          {!!errorMessage && (
            <Alert sx={{ my: 2 }} severity="error">
              Error: {errorMessage}
            </Alert>
          )}
          <Controller
            control={control}
            name="pay_period_start"
            render={({ field, fieldState: { error } }) => (
              <DatePicker
                label="Pay Period Start"
                {...field}
                renderInput={(params) => (
                  <TextField
                    required
                    fullWidth
                    margin="normal"
                    error={Boolean(error)}
                    helperText={error && error.message}
                    {...params}
                  />
                )}
              />
            )}
            rules={{
              required: true,
              validate: (value) =>
                !!parseDate(value) || "Date is not correctly formatted",
            }}
          />
          <Controller
            control={control}
            name="paid_on"
            render={({ field, fieldState: { error } }) => (
              <DatePicker
                label="Paid On"
                {...field}
                renderInput={(params) => (
                  <TextField
                    required
                    fullWidth
                    margin="normal"
                    error={Boolean(error)}
                    helperText={error && error.message}
                    {...params}
                  />
                )}
              />
            )}
            rules={{
              required: true,
              validate: (value) =>
                !!parseDate(value) || "Date is not correctly formatted",
            }}
          />
          <Controller
            control={control}
            name="blackout_period_start"
            render={({ field, fieldState: { error } }) => (
              <DatePicker
                label="Blackout Period Start"
                {...field}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    margin="normal"
                    error={Boolean(error)}
                    helperText={error && error.message}
                    {...params}
                  />
                )}
              />
            )}
            rules={{
              validate: (value) =>
                value
                  ? !!parseDate(value) || "Date is not correctly formatted"
                  : true,
            }}
          />
          {getBuildRegion() === BUILD_REGION.US && (
            <Controller
              control={control}
              name="lock_date"
              render={({ field, fieldState: { error } }) => (
                <DateTimePicker
                  label="Lock Date (UTC)"
                  {...field}
                  value={
                    parseDate(field.value)
                      ? makeLocalAppearUTC(field.value as Date)
                      : field.value
                  }
                  onChange={(value) =>
                    field.onChange({
                      target: {
                        value: parseDate(value)
                          ? localToUTC(value as Date)
                          : value,
                      },
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      margin="normal"
                      error={Boolean(error)}
                      helperText={error && error.message}
                      {...params}
                    />
                  )}
                />
              )}
              rules={{
                validate: (value) =>
                  value
                    ? !!parseDate(value) || "Date is not correctly formatted"
                    : true,
              }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            loading={isSubmitting}
            variant="contained"
          >
            Edit
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}
