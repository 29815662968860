import type { FieldArrayWithId, UseFormSetValue } from "react-hook-form"
import assert from "../../../../utils/assert"
import type { SegmentBuilderFormValues } from "../../SegmentBuilderDialog"
import { FILTER_OPTIONS } from "./constants/filterOptions"

interface GenerateSQLFromFiltersParams {
  filters: FieldArrayWithId<
    SegmentBuilderFormValues,
    "properties.filters",
    "arrayFieldId"
  >[]
  setValue: UseFormSetValue<SegmentBuilderFormValues>
}

export function generateSQLFromFilters({
  filters,
  setValue,
}: GenerateSQLFromFiltersParams) {
  const clauses = [
    "select employees.* from employees join user_roles on user_roles.entity_id = employees.employee_id",
  ]
  const wheres: string[] = []
  const tables: string[] = []
  const withs: string[] = []

  filters.forEach((filter) => {
    const matchingFilter = FILTER_OPTIONS.find(({ id }) => filter.id === id)
    assert(
      matchingFilter,
      "Could not find a local definition of an existing segment filter"
    )

    matchingFilter.tables.forEach((table) => {
      if (!tables.includes(table)) {
        tables.push(table)
      }
    })

    if (matchingFilter.where) {
      wheres.push(matchingFilter.where)
    }
  })

  tables.forEach((table) => {
    switch (table) {
      case "companies":
        clauses.push("join companies using (company_id)")
        break
      case "company_integrations":
        clauses.push("left join company_integrations using (company_id)")
        break
      case "enrollment_history":
        withs.push(
          "enrollment_history as (select * from (SELECT *, row_number() OVER (PARTITION BY employee_id ORDER BY created_at DESC) as row_num FROM employee_enrollment_history WHERE employee_id = %(employee_id)s OR %(employee_id)s is NULL) as eeh where row_num = 1)"
        )
        clauses.push("join enrollment_history using (employee_id)")
        break
      case "employee_in_app_dialogs":
        clauses.push("left join employee_in_app_dialogs using (employee_id)")
        break
      case "savings_accounts":
        clauses.push("left join savings_accounts using (employee_id)")
        break
      case "user_auth_tokens":
        clauses.push("left join user_auth_tokens using (user_id)")
        break
      case "user_payment_reminders":
        clauses.push("left join user_payment_reminders using (user_id)")
        break
      case "transfers":
        clauses.push("left join transfers using (employee_id)")
        break
      case "last_transfer":
        withs.push(
          "last_transfer as (select * from (SELECT *, row_number() OVER (PARTITION BY employee_id ORDER BY created_at DESC) as row_num FROM transfers WHERE employee_id = %(employee_id)s OR %(employee_id)s is NULL) as tran where row_num = 1)"
        )
        clauses.push("left join last_transfer using (employee_id)")
        break
      case "email_logs":
        clauses.push("left join email_logs using (user_id)")
        break
      case "app_analytics":
        clauses.push("join app_analytics using (employee_id)")
        break
      case "balance_history":
        withs.push(
          "balance_history as (select * from (SELECT *, row_number() OVER (PARTITION BY employee_id ORDER BY created_at DESC) as row_num FROM employee_balance_history WHERE employee_id = %(employee_id)s OR %(employee_id)s is NULL) as ebh where row_num = 1)"
        )
        clauses.push("join balance_history using (employee_id)")
        break
    }
  })

  let sqlStr = ""

  if (withs.length > 0) {
    sqlStr += " with " + withs.join(",\r\n")
  }

  sqlStr += clauses.join("\r\n")
  sqlStr += " where (employee_id = %(employee_id)s or %(employee_id)s is NULL)"
  if (wheres.length > 0) {
    const whereClause = wheres.join(") and (")
    sqlStr += ` and (${whereClause})\r\n`
  }

  setValue("sql", sqlStr)
}
