import { LoadingButton } from "@mui/lab"
import { Alert, Box, Stack } from "@mui/material"
import { CollapsibleCard, ControlledTextField } from "components"
import { useNotification } from "context/notification"
import { useForm } from "react-hook-form"
import { useParams } from "react-router-dom"
import { pickErrorMessage } from "utils/pickErrorMessage"
import assert from "../../../../utils/assert"
import usePostPayScheduleDemoDateGenerate from "../../query-hooks/usePostPayScheduleDemoDateGenerate"

export const GenerateDemoDates = () => {
  const notify = useNotification()
  const { id: payScheduleId } = useParams()
  assert(payScheduleId)

  const { mutate, isLoading, isError, error } =
    usePostPayScheduleDemoDateGenerate(payScheduleId, {
      onSuccess: () => {
        reset()
        notify.that("pay schedule dates").successfully("generated")
      },
    })

  const { handleSubmit, reset, control } = useForm<{
    date_in_first_week: string
  }>({
    defaultValues: {
      date_in_first_week: "",
    },
  })

  const handleFormSubmit = handleSubmit((values) => mutate(values))

  return (
    <CollapsibleCard title="Generate Demo Dates" type="action">
      <form id="generate-pay-schedule-demo-dates" onSubmit={handleFormSubmit}>
        <Box component="p">THIS IS INTENDED FOR DEMO USE.</Box>
        <Box component="p">
          It generates dates for a four-weekly schedule that is paid on
          Saturdays, to ensure that employees have a chance to accrue balance
          before demos on a Monday.
        </Box>
        <Stack spacing={4}>
          {isError && <Alert severity="error">{pickErrorMessage(error)}</Alert>}
          <ControlledTextField
            name="date_in_first_week"
            control={control}
            label="Date in first week"
            rules={{
              required: true,
            }}
            fieldProps={{
              type: "date",
              required: true,
              fullWidth: true,
              autoComplete: "off",
            }}
          />
          <LoadingButton type="submit" variant="contained" loading={isLoading}>
            Generate Dates
          </LoadingButton>
        </Stack>
      </form>
    </CollapsibleCard>
  )
}
