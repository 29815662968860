import { CollapsibleCard } from "components"
import { Alert, Grid, Stack } from "@mui/material"
import type { SubmitHandler } from "react-hook-form"
import { useForm } from "react-hook-form"
import { LoadingButton } from "@mui/lab"
import { useNotification } from "context/notification"
import { useState } from "react"
import { pickErrorMessage } from "utils/pickErrorMessage"
import { safeHtmlDecode } from "utils/safeHtmlDecode"
import { LinkedCampaignsFields } from "pages/MultimediaCampaigns/components/LinkedCampaignsForm/LinkedCampaignsFields"
import usePostLinkedCampaigns from "pages/MultimediaCampaigns/query-hooks/usePostLinkedCampaigns"

type FormValues = {
  experiment_id: string
  treatments: Array<{
    treatment_id: string
    multimedia_campaign_id: string
  }>
}

const LinkNewMultimediaCampaignRCT = () => {
  const notify = useNotification()
  const [error, setError] = useState<Error | null>(null)

  const form = useForm<FormValues>({
    defaultValues: {
      experiment_id: "",
      treatments: [],
    },
  })

  const { mutateAsync: postLinkedCampaigns } = usePostLinkedCampaigns({
    onSuccess: () =>
      notify.that("RCT campaign linked treatments").successfully("created"),
    onError: () =>
      notify.that("RCT campaign linked treatments").erroredWhile("creating"),
  })

  const handleLinkCampaign: SubmitHandler<FormValues> = async (values) => {
    setError(null)
    try {
      await postLinkedCampaigns({
        experiment_id: values.experiment_id,
        treatments: values.treatments,
      })
      form.reset()
    } catch (error) {
      setError(error as Error)
    }
  }

  return (
    <Grid item xs={12}>
      <CollapsibleCard title="Link RCT treatments to campaigns" type="main">
        <form onSubmit={form.handleSubmit(handleLinkCampaign)}>
          <Stack spacing={4}>
            {error ? (
              <Grid item xs={12}>
                <Alert severity="error">
                  {safeHtmlDecode(pickErrorMessage(error))}
                </Alert>
              </Grid>
            ) : null}
            <LinkedCampaignsFields {...form} />
            <LoadingButton type="submit" variant="contained">
              Save
            </LoadingButton>
          </Stack>
        </form>
      </CollapsibleCard>
    </Grid>
  )
}

export default LinkNewMultimediaCampaignRCT
