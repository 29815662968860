import { useQuery } from "@tanstack/react-query"
import { endpoints } from "api"

export const getQueryKey = (id?: string) => ["cais-reports-detail", id] as const

function useCaisReportDetail(id?: string) {
  const endpoint = endpoints.caisReports.details(id)

  return useQuery(getQueryKey(id), endpoint.get, {
    select: (data) => data.data.data,
  })
}

useCaisReportDetail.getQueryKey = getQueryKey

export default useCaisReportDetail
