import { Controller, useForm, useFormState } from "react-hook-form"
import { useNotification } from "context/notification"
import { LoadingButton } from "@mui/lab"
import { Alert, Box, Divider, Grid, Skeleton, Stack } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import useCategory from "pages/DiscountConfig/query-hooks/useCategory"
import { useUpdateCategory } from "pages/DiscountConfig/query-hooks/useUpdateCategory"
import { safeHtmlDecode } from "utils/safeHtmlDecode"
import { pickErrorMessage } from "utils/pickErrorMessage"
import useDiscountRetailers from "pages/Discounts/query-hooks/useDiscountRetailers"
import { DiscountAutocomplete } from "pages/DiscountConfig/common/DiscountAutocomplete"
import { ControlledCheckboxField, ControlledTextField } from "components"
import assert from "../../../../utils/assert"

export type CategoryFormFieldsType = {
  properties: {
    top_retailer_ids: string[]
    hide_category: boolean
    shuffle_top_retailers: boolean
  }
  display_name: string
}

export const CategoryForm = () => {
  const notify = useNotification()
  const navigate = useNavigate()
  const { id } = useParams()
  assert(id)
  const { data: category, refetch } = useCategory(id)
  const { data: retailers, isLoading: retailersLoading } =
    useDiscountRetailers()

  const {
    mutate,
    error,
    isLoading: saving,
  } = useUpdateCategory({
    discount_category_id: id,
    options: {
      onSuccess: () => {
        refetch()
        notify.that("retailer").successfully("updated")
        navigate("/data/discounts/categories")
      },
      onError: () => notify.that("category").erroredWhile("updating"),
    },
  })

  const form = useForm<CategoryFormFieldsType>({
    defaultValues: {
      display_name: category?.display_name || "",
      properties: {
        top_retailer_ids: category?.properties.top_retailer_ids || [],
        hide_category: category?.properties.hide_category || false,
        shuffle_top_retailers:
          category?.properties.shuffle_top_retailers || false,
      },
    },
  })

  const { isDirty } = useFormState({
    control: form.control,
  })

  return (
    <form
      onSubmit={form.handleSubmit(({ ...values }) => {
        mutate(values)
      })}
    >
      {retailersLoading ? (
        <Skeleton width="100%" height="20vh" />
      ) : (
        <Box display="flex" gap={4}>
          <Stack spacing={4} flex={1}>
            {error ? (
              <Grid item xs={12}>
                <Alert severity="error">
                  {safeHtmlDecode(pickErrorMessage(error))}
                </Alert>
              </Grid>
            ) : null}
            <ControlledTextField
              name="display_name"
              label="Display name"
              control={form.control}
              rules={{
                required: true,
              }}
            />
            <Divider />
            <Alert severity="info">
              Top retailers can be chosen below. These will show up as the first
              retailers in the category.
            </Alert>
            <Controller
              name="properties.top_retailer_ids"
              control={form.control}
              render={({ field }) => {
                return (
                  <DiscountAutocomplete
                    retailers={retailers}
                    field={field}
                    label="Top retailers"
                  />
                )
              }}
            />
            <ControlledCheckboxField
              name="properties.shuffle_top_retailers"
              label="Randomise order of the top retailers"
              fieldProps={{ size: "medium" }}
              control={form.control}
            />
            <Divider />
            <ControlledCheckboxField
              name="properties.hide_category"
              label="Hide category from home and filters"
              fieldProps={{ size: "medium" }}
              control={form.control}
            />
            <LoadingButton
              variant="contained"
              loading={saving}
              type="submit"
              sx={{ minWidth: 100 }}
              disabled={!isDirty}
            >
              Save
            </LoadingButton>
          </Stack>
        </Box>
      )}
    </form>
  )
}
