import { useQuery } from "@tanstack/react-query"
import { endpoints } from "api"

export const getQueryKey = () => ["multimediaCampaigns"] as const

function useMultimediaCampaigns() {
  const endpoint = endpoints.multimediaCampaigns.all()

  return useQuery(getQueryKey(), endpoint.get, {
    select: (data) => data.data.data,
  })
}

useMultimediaCampaigns.getQueryKey = getQueryKey

export default useMultimediaCampaigns
