import { useEffect } from "react"
import { Controller, useForm } from "react-hook-form"
import { useParams } from "react-router-dom"
import { zonedTimeToUtc } from "date-fns-tz"

import { LoadingButton } from "@mui/lab"
import { Alert, Stack, TextField } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers"
import { CollapsibleCard, ControlledTextField } from "components"

import { useNotification } from "context/notification"
import { pickErrorMessage } from "utils/pickErrorMessage"
import { formatDate, parseDate } from "utils/date"
import usePaySchedule from "pages/PaySchedulesDates/query-hooks/usePaySchedule"
import assert from "../../../../utils/assert"
import usePostPayScheduleDateGenerate, {
  type PayScheduleDateGeneratePayload,
} from "../../query-hooks/usePostPayScheduleDateGenerate"

type GenerateDatesUSFormValues = {
  pay_period_start: string
  pay_day_of_month: string
  initial_period_start: Date | null
  days_from_period_start_to_paid: string
  lock_date_days_delta: string
  lock_date_hours_delta: string
}

function isValidDayOfMonth(value: unknown) {
  const number = Number(value)
  return Number.isInteger(number) && Number(number) >= 1 && Number(number) <= 31
}

export const GenerateDatesUS = () => {
  const notify = useNotification()
  const { id: payScheduleId } = useParams()
  assert(payScheduleId)

  const { data: paySchedule } = usePaySchedule(payScheduleId)

  const { periodicity, accrual_type } = paySchedule || {}
  const isAligned = accrual_type === "ALIGNED"
  const isMonthly = periodicity === "MONTHLY"
  const isFortnightly = periodicity === "FORTNIGHT"
  const isWeekly = periodicity === "WEEKLY"
  const isFourWeekly = periodicity === "FOUR_WEEKLY"

  const { mutate, isLoading, isError, error } = usePostPayScheduleDateGenerate(
    payScheduleId,
    {
      onSuccess: () => {
        reset()
        notify.that("pay schedule dates").successfully("generated")
      },
    }
  )

  const { handleSubmit, reset, control } = useForm<GenerateDatesUSFormValues>({
    defaultValues: {
      pay_period_start: "",
      pay_day_of_month: "",
      initial_period_start: null,
      days_from_period_start_to_paid: "",
      lock_date_days_delta: "",
      lock_date_hours_delta: "",
    },
  })

  useEffect(() => {
    reset()
  }, [periodicity, accrual_type, reset])

  function formatDateForSubmission(date: Date) {
    return formatDate(zonedTimeToUtc(date, "UTC"), "yyyy-MM-dd") ?? ""
  }

  const handleFormSubmit = handleSubmit((values) => {
    // Remove any falsy values from the form values, as we only want to send what has been input by the user
    const keys = Object.keys(values) as Array<keyof GenerateDatesUSFormValues>
    const valuesToSend = keys.reduce<PayScheduleDateGeneratePayload>(
      (acc, key) => {
        if (values[key]) {
          if (
            key === "initial_period_start" &&
            values["initial_period_start"]
          ) {
            acc["initial_period_start"] = formatDateForSubmission(
              values["initial_period_start"]
            )
          } else if (key !== "initial_period_start") {
            acc[key] = values[key]
          }
        }
        return acc
      },
      {}
    )

    mutate(valuesToSend)
  })

  return (
    <CollapsibleCard title="Generate Dates" type="action">
      <form id="generate-pay-schedule-dates-us" onSubmit={handleFormSubmit}>
        <Stack spacing={4}>
          {isError && <Alert severity="error">{pickErrorMessage(error)}</Alert>}
          {isMonthly && (
            <>
              <ControlledTextField
                name="pay_period_start"
                control={control}
                label="Pay Period Start (1-31)"
                rules={{
                  required: isMonthly,
                  validate: (value) =>
                    isValidDayOfMonth(value) ||
                    "Pay Period Start must be a whole number between 1 and 31",
                }}
                fieldProps={{
                  type: "number",
                  required: true,
                  fullWidth: true,
                  autoComplete: "off",
                }}
              />
              {isMonthly && !isAligned && (
                <ControlledTextField
                  name="pay_day_of_month"
                  control={control}
                  label="Pay Day (1-31)"
                  rules={{
                    required: isMonthly && !isAligned,
                    validate: (value) =>
                      isValidDayOfMonth(value) ||
                      "Pay Day must be a whole number between 1 and 31",
                  }}
                  fieldProps={{
                    type: "string",
                    required: true,
                    fullWidth: true,
                    autoComplete: "off",
                  }}
                />
              )}
            </>
          )}
          {(isFourWeekly || isFortnightly || isWeekly) && (
            <Controller
              control={control}
              name="initial_period_start"
              render={({ field, fieldState: { error } }) => (
                <DatePicker
                  label="Initial Period Start"
                  {...field}
                  renderInput={(params) => (
                    <TextField
                      required
                      fullWidth
                      error={Boolean(error)}
                      helperText={error && error.message}
                      {...params}
                    />
                  )}
                />
              )}
              rules={{
                required: true,
                validate: (value) =>
                  !!parseDate(value) || "Date is not correctly formatted",
              }}
            />
          )}
          {!isMonthly && !isAligned && (
            <ControlledTextField
              name="days_from_period_start_to_paid"
              control={control}
              label="Days from Period Start to Paid On"
              rules={{
                required: true,
              }}
              fieldProps={{
                type: "number",
                required: true,
                fullWidth: true,
                autoComplete: "off",
              }}
            />
          )}
          <ControlledTextField
            name="lock_date_days_delta"
            control={control}
            label="Lock Date - Days before Paid On"
            rules={{
              required: false,
            }}
            fieldProps={{
              type: "number",
              required: false,
              fullWidth: true,
              autoComplete: "off",
            }}
          />
          <ControlledTextField
            name="lock_date_hours_delta"
            control={control}
            label="Lock Date - Hours before Paid On"
            rules={{
              required: false,
            }}
            fieldProps={{
              type: "number",
              required: false,
              fullWidth: true,
              autoComplete: "off",
            }}
          />
          <LoadingButton type="submit" variant="contained" loading={isLoading}>
            Generate Dates
          </LoadingButton>
        </Stack>
      </form>
    </CollapsibleCard>
  )
}
