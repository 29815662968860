import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  Tooltip,
} from "@mui/material"
import type { GridColDef, GridRenderCellParams } from "@mui/x-data-grid"
import { DataGrid, gridClasses } from "@mui/x-data-grid"
import { CollapsibleCard, SimpleGrid } from "components"
import "../styles.css"
import { useParams } from "react-router-dom"
import { Button } from "@mui/material"
import UpdateIcon from "@mui/icons-material/Edit"
import SaveIcon from "@mui/icons-material/Save"
import { useEffect, useMemo, useState } from "react"
import useCreditRiskMapUpdate from "pages/Credit/hooks/useCreditRiskMapUpdate"
import type { RiskMapping } from "api"
import _ from "lodash"
import { useNavigate } from "react-router-dom"
import useCreditRiskMappingList from "pages/Credit/hooks/useCreditRiskMapList"
import useCreditRiskMapCreate from "pages/Credit/hooks/useCreditRiskMapCreate"
import ButtonWithConfirmation from "components/ButtonWithConfirmation/ButtonWithConfirmation"
import { routes } from "routes"
import COLUMNS from "pages/Credit/RiskCompany.columns"
import CompanyConfigUpdateDialog from "pages/Credit/components/CompanyConfigUpdateDialog"
import FillDialog from "pages/Credit/components/FillDialog"

const getColumnDefinition = (
  name: string,
  editable: boolean,
  type?: string
) => {
  return {
    field: name,
    headerName: name !== "cr" ? name : "",
    headerClassName: "risk-header",
    editable: editable,
    width: 100,
    type: type === "decision" ? "string" : "number",
    sortable: false,
    disableColumnMenu: true,
    description:
      type === "ndi_modifier"
        ? name === "0"
          ? "Multiplier"
          : "Adder"
        : "Churn Rating",
    renderCell:
      name === "cr"
        ? ({ value }: GridRenderCellParams) => (
            <Tooltip title="Credit Rating">
              <span>{value}</span>
            </Tooltip>
          )
        : undefined,
  }
}

const generateRows = (
  columns: number,
  value?: number | string | undefined,
  type?: string
) => {
  return [...Array(20).keys()].map((i: number) => {
    return {
      cr: i + 1,
      ...Object.fromEntries(
        [...Array(columns).keys()].map((j) => [
          type === "ndi_modifier" ? j : j + 1,
          value,
        ])
      ),
    }
  })
}

const mapCompanyData = (data?: RiskMapping) =>
  (data?.companies ?? []).map((c) => ({
    ...c,
    loan_live_date: c.properties?.app_features?.sdl_enabled
      ? new Date(
          (c.properties.credit?.sdl_activation_date ?? "2024-04-05") +
            " " +
            (c.properties.credit?.sdl_activation_time ?? "00:00 UTC")
        )
      : null,
  }))

const getRowsFromData = (data: RiskMapping) => {
  const rows = []
  const meta = data?.meta?.interest_tiers ?? data?.meta?.limit_tiers ?? {}
  for (const [credit_rating, row] of Object.entries(data.table)) {
    const updated_row = Object.fromEntries(
      Object.entries(row).map(([key, value]) => [
        key,
        _.isEmpty(meta) ? value : meta[value],
      ])
    )
    rows.push({ cr: credit_rating, ...updated_row })
  }
  return rows
}

const DEFAULT_COLUMNS: GridColDef[] = [...Array(11).keys()].map((i) =>
  i === 0
    ? getColumnDefinition("cr", false)
    : getColumnDefinition(i.toString(), true)
)
const DEFAULT_COLUMNS_NDI: GridColDef[] = [...Array(3).keys()].map((i) =>
  i === 0
    ? getColumnDefinition("cr", false)
    : getColumnDefinition((i - 1).toString(), true, "ndi_modifier")
)

const DEFAULT_COLUMNS_DECISION: GridColDef[] = [...Array(11).keys()].map((i) =>
  i === 0
    ? getColumnDefinition("cr", false)
    : getColumnDefinition(i.toString(), true, "decision")
)

const getDefaultColumns = (type: string) => {
  switch (type) {
    case "decision":
      return DEFAULT_COLUMNS_DECISION
    case "ndi_modifier":
      return DEFAULT_COLUMNS_NDI
    default:
      return DEFAULT_COLUMNS
  }
}

const DEFAULT_ROWS = generateRows(8, undefined)

const DEFAULT_ROWS_NDI = generateRows(2, undefined, "ndi_modifier")

export const RiskMapEditor = ({
  data,
  isLoading = false,
}: {
  data?: RiskMapping
  isLoading?: boolean
}) => {
  const { product = "sdl", type = "interest_rate", name, version } = useParams()
  const navigate = useNavigate()

  const [isEditing, setEdit] = useState(
    data === undefined && isLoading === false
  )
  const [editCellId, setEditCellId] = useState<string | number>(-1)
  const [mappingName, setMappingName] = useState(name ?? "")
  const [updatedRows, setUpdatedRows] = useState(data?.table ?? {})
  const [rows, setRows] = useState<{ cr: string | number }[]>([])
  const [repAPR, setRepAPR] = useState<number | undefined>(undefined)

  const { data: mappingList } = useCreditRiskMappingList(product)
  const { mutate: create } = useCreditRiskMapCreate(product, type, mappingName)
  const { mutate: update } = useCreditRiskMapUpdate(
    product,
    type,
    mappingName,
    { onSuccess: () => setEdit(false) }
  )

  const companyData = useMemo(() => mapCompanyData(data), [data])

  const [columns, setColumns] = useState<GridColDef[]>([])
  const [initialColumnsCount, setInitialColumnsCount] = useState<number>(0)

  useEffect(() => {
    if (!data) {
      setRows(type === "ndi_modifier" ? DEFAULT_ROWS : DEFAULT_ROWS_NDI)
      const defaultColumns = getDefaultColumns(type)
      setColumns(defaultColumns)
      setInitialColumnsCount(defaultColumns.length)
      return
    }
    setRows(getRowsFromData(data))
    setColumns([
      getColumnDefinition("cr", false),
      ...Object.keys(data.table["1"]).map((key) =>
        getColumnDefinition(key, isEditing, type)
      ),
    ])
    setInitialColumnsCount(1 + Object.keys(data.table["1"]).length)
  }, [data, isEditing, type])

  const [meta, reverseMeta] = useMemo(() => {
    const uniqueValues = new Set()
    if (updatedRows) {
      Object.values(updatedRows).forEach((row) => {
        Object.values(row).forEach((value) => {
          uniqueValues.add(value)
        })
      })
    }
    const meta = Object.fromEntries(
      Array.from(uniqueValues)
        .sort()
        .reverse()
        .map((value, index) => [
          String.fromCharCode(97 + index).toUpperCase(),
          value,
        ])
    )
    const reverseMeta = Object.fromEntries(
      Object.entries(meta).map(([key, value]) => [value, key])
    )
    return [meta, reverseMeta]
  }, [updatedRows])

  const payload = useMemo(() => {
    const getApiData = () =>
      Object.fromEntries(
        Object.entries(updatedRows).map(([key, value]) => [
          key,
          type === "ndi_modifier"
            ? Object.values(value)
            : Object.fromEntries(
                Object.entries(value).map(([k, v]) => [k, reverseMeta[v]])
              ),
        ])
      ) as RiskMapping["table"]

    let payload = { table: updatedRows, meta: {} }
    switch (type) {
      case "limit": {
        payload = { table: getApiData(), meta: { limit_tiers: meta } }
        break
      }
      case "ndi_modifier": {
        payload = { table: getApiData(), meta: {} }
        break
      }
      case "interest_rate": {
        payload = {
          table: getApiData(),
          meta: { interest_tiers: meta, rep_apr: repAPR },
        }
        break
      }
    }
    return payload as RiskMapping
  }, [updatedRows, type, reverseMeta, meta, repAPR])

  useEffect(() => setRepAPR(data?.meta?.rep_apr), [data])

  const addColumn = () => {
    const nextColumnNumber =
      Object.keys(Object.values(updatedRows)[0] || {}).length + 1

    const rowsWithNewColumn = Object.fromEntries(
      Object.entries(updatedRows).map(([creditRating, row]) => [
        creditRating,
        {
          ...row,
          [nextColumnNumber.toString()]: Array.isArray(row)
            ? row[nextColumnNumber - 1]
            : row[(nextColumnNumber - 1).toString()],
        },
      ])
    )

    const newColumns = [
      ...columns,
      getColumnDefinition(nextColumnNumber.toString(), isEditing, type),
    ]

    setUpdatedRows(rowsWithNewColumn)
    setRows(
      Object.entries(rowsWithNewColumn).map(([cr, row]) => ({ cr, ...row }))
    )
    setColumns(newColumns)
  }

  const removeColumn = () => {
    const lastColumnNumber = Object.keys(
      Object.values(updatedRows)[0] || {}
    ).length
    if (lastColumnNumber <= 1) return

    const rowsWithRemovedColumn = Object.fromEntries(
      Object.entries(updatedRows).map(([creditRating, row]) => [
        creditRating,
        Object.fromEntries(
          Object.entries(row).filter(
            ([key]) => parseInt(key) !== lastColumnNumber
          )
        ),
      ])
    )

    const newColumns = columns.slice(0, -1)

    setUpdatedRows(rowsWithRemovedColumn)
    setRows(
      Object.entries(rowsWithRemovedColumn).map(([cr, row]) => ({ cr, ...row }))
    )
    setColumns(newColumns)
  }

  return (
    <div style={{ width: "100%" }}>
      <CollapsibleCard title="Attributes" type="main">
        <Grid container spacing={6}>
          <Grid item>
            {data && mappingList && product && type && name ? (
              <FormControl sx={{ width: 150 }}>
                <InputLabel id="version-select-label">
                  Mapping Version
                </InputLabel>
                <Select
                  labelId="version-select-label"
                  id="version-select"
                  value={version}
                  label="Mapping Name"
                  onChange={(event) => {
                    setEdit(false)
                    navigate(
                      routes.riskMap({
                        product,
                        type,
                        name: mappingName,
                        version: event.target.value,
                      }),
                      { replace: true }
                    )
                  }}
                >
                  {mappingList[type][name].sort().map((value) => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : null}
          </Grid>

          {data === undefined ? (
            <Grid item>
              <TextField
                id="mapping_name"
                sx={{ width: 150 }}
                onChange={(event) => setMappingName(event.target.value)}
                label={"Name"}
              />
            </Grid>
          ) : null}

          {type === "interest_rate" && !isLoading ? (
            <Grid item>
              <TextField
                id="rep_apr"
                type="number"
                label={"Representative APR"}
                variant="outlined"
                value={repAPR}
                disabled={!isEditing}
                onChange={(event) => setRepAPR(parseFloat(event.target.value))}
              />
            </Grid>
          ) : null}

          {isEditing ? (
            <Grid item>
              <FillDialog
                action={(value) =>
                  setRows(
                    generateRows(
                      columns.length - 1,
                      type === "decision" ? value : parseFloat(value),
                      type
                    )
                  )
                }
              />
            </Grid>
          ) : null}

          {isEditing && data && !isLoading ? (
            <>
              <Grid item>
                <Button
                  style={{ height: "100%" }}
                  variant="contained"
                  onClick={() => addColumn()}
                  disabled={columns.length >= getDefaultColumns(type).length}
                >
                  Add Column
                </Button>
              </Grid>
              <Grid item>
                <Button
                  style={{ height: "100%" }}
                  variant="contained"
                  onClick={() => removeColumn()}
                  disabled={columns.length <= initialColumnsCount}
                >
                  Remove Column
                </Button>
              </Grid>
            </>
          ) : null}

          {isEditing && data && !isLoading ? (
            <Grid item>
              <ButtonWithConfirmation
                style={{ height: "100%" }}
                label="Cancel"
                title="Confirm"
                variant="outlined"
                message="Are you sure you want to discard your changes?"
                onConfirm={() => {
                  setRows(getRowsFromData(data))
                  setEdit(false)
                  return true
                }}
              />
            </Grid>
          ) : null}

          <Grid item>
            {isEditing ? (
              <ButtonWithConfirmation
                style={{ height: "100%" }}
                variant="contained"
                endIcon={<SaveIcon />}
                disabled={mappingName === "" && !data}
                title="Confirm"
                message="Are you sure you want to save your changes?"
                label="Save"
                onConfirm={() => {
                  if (data) {
                    update(
                      { data: payload },
                      {
                        onSuccess: () => {
                          navigate(
                            routes.riskMap({
                              product,
                              type,
                              name: mappingName,
                              version: (
                                Math.max(
                                  ...(mappingList?.[type]?.[mappingName] ?? [0])
                                ) + 1
                              ).toString(),
                            })
                          )
                        },
                      }
                    )
                  } else {
                    create(
                      { data: payload },
                      {
                        onSuccess: () => {
                          navigate(
                            routes.riskMap({
                              product,
                              type,
                              name: mappingName,
                              version: "1",
                            }),
                            { replace: true }
                          )
                        },
                      }
                    )
                  }
                  return true
                }}
              />
            ) : (
              <Button
                style={{ height: "100%" }}
                variant="contained"
                endIcon={<UpdateIcon />}
                onClick={() => setEdit(true)}
              >
                Update
              </Button>
            )}
          </Grid>
        </Grid>
      </CollapsibleCard>

      <CollapsibleCard title="Mapping Grid" type="main">
        {isLoading ? (
          <Skeleton
            role="progressbar"
            height={600}
            sx={{ transform: "none" }}
          />
        ) : (
          <>
            <DataGrid
              getRowId={(row) => row.cr}
              autoHeight
              disableSelectionOnClick
              hideFooterPagination={true}
              columns={columns}
              rows={rows}
              getRowClassName={(_) => "risk-row"}
              onStateChange={(state) => {
                const data = { ...state.rows.idRowsLookup }
                Object.entries(data).forEach(
                  ([key, value]) =>
                    (data[key] = _.omit(value as object, ["cr"]))
                )
                setUpdatedRows(data)
              }}
              onCellEditStart={(params) =>
                setEditCellId(params.id + params.field)
              }
              onCellEditStop={(_) => setEditCellId("")}
              sx={{
                "&, [class^=MuiDataGrid]": { border: "none" },
                "& div div div div >.MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& > .MuiDataGrid-columnSeparator": {
                  display: "none",
                },

                [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
                  {
                    outline: "none",
                  },
              }}
              getCellClassName={(params) => {
                if (isEditing && params.id + params.field === editCellId) {
                  return ""
                }
                if (params.field === "cr") {
                  return "risk-border-cell"
                }
                if (params.value === undefined) {
                  return "risk-cell-empty"
                }

                const value =
                  reverseMeta && type !== "decision"
                    ? reverseMeta[params.value]
                    : params.value
                switch (value) {
                  case "O":
                    return "risk-cell-approve"
                  case "X":
                    return "risk-cell-decline"
                  case "A":
                  case "B":
                  case "C":
                  case "D":
                  case "E":
                  case "F":
                    return "risk-cell-" + value.toLowerCase()
                  default:
                    return "risk-cell-empty"
                }
                return ""
              }}
            />
          </>
        )}
      </CollapsibleCard>
      {data && companyData && mappingList ? (
        <CollapsibleCard title="Mapping Usage" type="main">
          <>
            <CompanyConfigUpdateDialog
              riskMapping={data}
              mappingList={mappingList}
            />

            <br />
            <br />

            <SimpleGrid
              idField={"company_id"}
              initialState={{
                sorting: {
                  sortModel: [{ field: "loan_live_date", sort: "desc" }],
                },
              }}
              rows={companyData}
              columns={COLUMNS}
              quickFilter
              pagination
              disableColumnFilter={true}
              rowsPerPageOptions={[100, 500, 99999]}
            />
          </>
        </CollapsibleCard>
      ) : null}
    </div>
  )
}
