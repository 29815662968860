import { Grid, Button, Box } from "@mui/material"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import CancelIcon from "@mui/icons-material/Cancel"
import { CollapsibleCard, SimpleGrid } from "components"
import { Link } from "@mui/material"
import type { GridColumns } from "@mui/x-data-grid"
import { useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { format } from "date-fns"
import useCaisReportList from "./hooks/useCaisReportList"
import useCaisReportsGenerate from "./hooks/useCaisReportsGenerate"
export default function CaisReportList() {
  const navigate = useNavigate()

  const { data: caisReports, isLoading: isLoadingReports } = useCaisReportList()
  const { mutate: generateCaisReport, isLoading: isLoadingGenerate } =
    useCaisReportsGenerate()
  const columns: GridColumns = useMemo(
    () => [
      {
        field: "year",
        headerName: "Year",
        flex: 1,
      },
      {
        field: "month",
        headerName: "Month",
        flex: 1,
      },
      {
        field: "generated_at_utc",
        headerName: "Generated At",
        flex: 1,
        valueFormatter: (params) =>
          params.value
            ? format(new Date(params.value), "yyyy-MM-dd HH:mm:ss")
            : "",
      },
      {
        field: "verified_at_utc",
        headerName: "Verified At",
        flex: 1,
        valueFormatter: (params) =>
          params.value
            ? format(new Date(params.value), "yyyy-MM-dd HH:mm:ss")
            : "",
      },
      {
        field: "s3_report_url",
        headerName: "Report",
        flex: 1,
        renderCell: (params) =>
          params.value ? (
            <Link href={params.value} target="_blank" rel="noopener noreferrer">
              Download Report
            </Link>
          ) : null,
      },
      {
        field: "properties.overall_result",
        headerName: "Automated Checks",
        flex: 1,
        valueGetter: (params) => params.row.properties.overall_result,
        renderCell: (params) =>
          params.value === "PASSED" ? (
            <CheckCircleIcon color="success" />
          ) : (
            <CancelIcon color="error" />
          ),
      },
      {
        field: "deleted_cra_account_codes",
        headerName: "No Rows Deleted",
        flex: 1,
        valueGetter: (params) =>
          params.row.properties.deleted_cra_account_codes,
        renderCell: (params) => {
          const deletedCodes = params.value
          return !deletedCodes?.length ? (
            <CheckCircleIcon color="success" />
          ) : (
            <CancelIcon color="error" />
          )
        },
      },
      {
        field: "details",
        headerName: "Details",
        flex: 1,
        renderCell: (params) => (
          <Button
            variant="contained"
            size="small"
            onClick={() =>
              navigate(`/credit/cais-reports/${params.row.cra_report_id}`)
            }
          >
            View
          </Button>
        ),
      },
    ],
    [navigate]
  )

  return (
    <Grid item xs={16}>
      <CollapsibleCard title="CAIS Reports" type="main">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SimpleGrid
              isLoading={isLoadingReports}
              columns={columns}
              rows={caisReports || []}
              idField="cra_report_id"
              initialState={{
                sorting: {
                  sortModel: [{ field: "generated_at_utc", sort: "desc" }],
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Box display="flex">
              <Button
                variant="contained"
                color="primary"
                onClick={() => generateCaisReport()}
                disabled={isLoadingGenerate}
              >
                Generate Report
              </Button>
            </Box>
          </Grid>
        </Grid>
      </CollapsibleCard>
    </Grid>
  )
}
