import PaydaysApi from "api"
import type { AxiosResponse } from "axios"
import { pathWithSearchParams } from "utils/pathWithSearchParams"

type Method = "get" | "post" | "update" | "patch" | "put" | "delete" | "options"
type EndpointFetcher<T, P = void> = (payload?: P) => Promise<AxiosResponse<T>>

type GenericMap<T extends { [key: string]: unknown }> = {
  [Key in string]: T[Key]
}

export type EndpointFetcherMap<T> = Partial<Record<Method, EndpointFetcher<T>>>

function endpointBuilder<Api extends GenericMap<Api>>(url: string, api: Api) {
  const builder = {
    _get: <K, T = { data: K }>() =>
      endpointBuilder(url, {
        ...api,
        get: () =>
          PaydaysApi.get<T>(url, {
            includeToken: true,
          }),
      }),
    _options: <T>() =>
      endpointBuilder(url, {
        ...api,
        options: () =>
          PaydaysApi.options<T>(url, {
            includeToken: true,
          }),
      }),
    _post: <T = unknown>() =>
      endpointBuilder(url, {
        ...api,
        post: <P>(payload?: P) =>
          PaydaysApi.post<T>(url, payload, {
            includeToken: true,
          }),
      }),
    _delete: <T = unknown>() =>
      endpointBuilder(url, {
        ...api,
        delete: <P, Q = void>(payload?: P, config?: { params?: Q }) =>
          PaydaysApi.delete<T>(url, {
            data: payload,
            params: config?.params,
            includeToken: true,
          }),
      }),
    _patch: <T = unknown>() =>
      endpointBuilder(url, {
        ...api,
        patch: <P>(payload?: P) =>
          PaydaysApi.patch<T>(url, payload, {
            includeToken: true,
          }),
      }),
    _put: <T = unknown>() =>
      endpointBuilder(url, {
        ...api,
        put: <P>(payload?: P) =>
          PaydaysApi.put<T>(url, payload, {
            includeToken: true,
          }),
      }),
  }

  const seal = () => ({ url, ...api })

  return {
    ...api,
    ...builder,
    url,
    seal,
  }
}

export function E<T extends GenericMap<T>>(
  path: string,
  searchParams?: Record<string, string | number | boolean | null | undefined>
) {
  const url = searchParams ? pathWithSearchParams(path, searchParams) : path

  return endpointBuilder<T>(url, {} as T)
}
