import { CollapsibleCard, ControlledTextField } from "components"
import { Button, MenuItem, Stack, IconButton } from "@mui/material"
import { type UseFormReturn, useFieldArray } from "react-hook-form"
import type { FC } from "react"
import AddIcon from "@mui/icons-material/Add"
import DeleteIcon from "@mui/icons-material/Delete"
import useMultimediaCampaigns from "../../query-hooks/useMultimediaCampaigns"
import useExperiments from "../../query-hooks/useTrials"
import useTreatments from "../../query-hooks/useTrialTreatments"

export type LinkedCampaignsFormValues = {
  experiment_id: string
  treatments: Array<{
    treatment_id: string
    multimedia_campaign_id: string
  }>
}

export const LinkedCampaignsFields: FC<
  UseFormReturn<LinkedCampaignsFormValues>
> = ({ control, watch }) => {
  const { data: experiments, isLoading: isExperimentsLoading } =
    useExperiments()
  const { data: treatments, isLoading: isTreatmentsLoading } = useTreatments(
    watch("experiment_id")
  )
  const { data: campaigns, isLoading: isCampaignsLoading } =
    useMultimediaCampaigns()

  const { fields, append, remove } = useFieldArray({
    control,
    name: "treatments",
  })

  return (
    <Stack spacing={2}>
      <ControlledTextField
        name="experiment_id"
        label="Experiment"
        control={control}
        rules={{ required: true }}
        fieldProps={{ select: true }}
      >
        {isExperimentsLoading && <MenuItem value="">Loading...</MenuItem>}
        {experiments?.map(({ experiment_id, name, code }) => (
          <MenuItem key={experiment_id} value={experiment_id}>
            {code} ({name})
          </MenuItem>
        ))}
      </ControlledTextField>

      <CollapsibleCard title="Treatment Campaign Links" startExpanded>
        <Stack spacing={2}>
          {fields.map((field, index) => (
            <Stack
              key={field.id}
              direction="row"
              spacing={2}
              alignItems="center"
            >
              <ControlledTextField
                name={`treatments.${index}.treatment_id`}
                label="Treatment"
                control={control}
                sx={{ minWidth: 200 }}
                rules={{ required: true }}
                fieldProps={{ select: true }}
              >
                {isTreatmentsLoading && (
                  <MenuItem value="">Loading...</MenuItem>
                )}
                {treatments?.map(({ treatment_id, code }) => (
                  <MenuItem key={treatment_id} value={treatment_id}>
                    {code}
                  </MenuItem>
                ))}
              </ControlledTextField>

              <ControlledTextField
                name={`treatments.${index}.multimedia_campaign_id`}
                label="Multimedia Campaign"
                control={control}
                sx={{ minWidth: 200 }}
                rules={{ required: true }}
                fieldProps={{ select: true }}
              >
                {isCampaignsLoading && <MenuItem value="">Loading...</MenuItem>}
                {campaigns?.map(({ multimedia_campaign_id, title }) => (
                  <MenuItem
                    key={multimedia_campaign_id}
                    value={multimedia_campaign_id}
                  >
                    {title}
                  </MenuItem>
                ))}
              </ControlledTextField>

              <IconButton onClick={() => remove(index)}>
                <DeleteIcon />
              </IconButton>
            </Stack>
          ))}

          <Button
            startIcon={<AddIcon />}
            onClick={() =>
              append({
                treatment_id: "",
                multimedia_campaign_id: "",
              })
            }
          >
            Add Treatment Campaign Link
          </Button>
        </Stack>
      </CollapsibleCard>
    </Stack>
  )
}
