import { useQuery } from "@tanstack/react-query"
import { endpoints } from "api"

export const getQueryKey = () => ["cais-reports"] as const

function useCaisReportList() {
  const endpoint = endpoints.caisReports.all()

  return useQuery(getQueryKey(), endpoint.get, {
    select: (data) => data.data.data,
  })
}

useCaisReportList.getQueryKey = getQueryKey

export default useCaisReportList
