import type {
  DataGridProps,
  GridColumns,
  GridCsvExportOptions,
  GridRowId,
  GridRowParams,
  GridToolbarQuickFilterProps,
  GridValidRowModel,
} from "@mui/x-data-grid"
import { GridToolbar } from "@mui/x-data-grid"
import { DataGrid } from "@mui/x-data-grid"
import { NoRowsOverlay } from "./NoRowsOverlay"
import "./SimpleGrid.css"
const simpleGridStyle = {
  "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
    py: 2,
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    textOverflow: "clip",
    whiteSpace: "break-spaces",
    lineHeight: 1,
  },
}

const quickFilterProps: {
  showQuickFilter: boolean
  quickFilterProps: GridToolbarQuickFilterProps
  csvOptions: GridCsvExportOptions
} = {
  showQuickFilter: true,
  quickFilterProps: { debounceMs: 200 },
  csvOptions: { utf8WithBom: true },
}

export type SimpleGridProps<T extends GridValidRowModel> = {
  quickFilter?: boolean
  disableColumnMenu?: boolean
  disableColumnFilter?: boolean
  disableColumnSelection?: boolean
  disableDensitySelector?: boolean
  disableSelectionOnClick?: boolean
  isLoading?: boolean
  columns: GridColumns<T>
  rows: Array<T>
  idField: keyof T
  noRowsText?: string
  initialPaginationPageSize?: number
  onRightClick?: (id: GridRowId) => void
  contextMenuOptions?: {
    label: string
    action: (id: GridRowParams) => void
  }[]
} & Omit<DataGridProps<T>, "loading" | "getRowId" | "columns" | "rows">

export function SimpleGrid<T extends GridValidRowModel>({
  quickFilter,
  disableColumnMenu = true,
  disableColumnFilter = true,
  disableColumnSelection = true,
  disableDensitySelector = true,
  disableSelectionOnClick = true,
  isLoading,
  columns,
  rows,
  idField,
  sx = null,
  noRowsText,
  initialPaginationPageSize,
  onRightClick,
  contextMenuOptions,
  ...rest
}: SimpleGridProps<T>) {
  const handleContextMenu = (
    event: React.MouseEvent,
    params: GridRowParams
  ) => {
    event.preventDefault()
    console.log("params", params)
    if (contextMenuOptions?.length) {
      const menu = document.createElement("div")
      menu.className = "custom-context-menu"
      menu.style.position = "fixed"
      menu.style.left = `${event.clientX}px`
      menu.style.top = `${event.clientY}px`

      contextMenuOptions.forEach((option) => {
        const item = document.createElement("div")
        item.className = "context-menu-item"
        item.textContent = option.label
        item.onclick = () => {
          option.action(params)
          document.body.removeChild(menu)
        }
        menu.appendChild(item)
      })

      const removeMenu = () => {
        if (document.body.contains(menu)) {
          document.body.removeChild(menu)
        }
        document.removeEventListener("click", removeMenu)
      }

      document.addEventListener("click", removeMenu)
      document.body.appendChild(menu)
    }
  }

  return (
    <DataGrid
      loading={isLoading}
      columns={columns}
      rows={rows}
      components={{
        Toolbar: GridToolbar,
        NoRowsOverlay: noRowsText ? NoRowsOverlay : undefined,
      }}
      componentsProps={{
        toolbar: quickFilter ? quickFilterProps : {},
        noRowsOverlay: noRowsText ? { message: noRowsText } : {},
        row: {
          onContextMenu: (event: React.MouseEvent) => {
            const rowElement = (event.currentTarget as HTMLElement).closest(
              ".MuiDataGrid-row"
            )
            if (rowElement) {
              const rowId = rowElement.getAttribute("data-id")
              const row = rows.find((r) => (r[idField] as GridRowId) === rowId)
              if (row) {
                const params: GridRowParams = {
                  id: rowId as GridRowId,
                  row,
                  columns,
                  getValue: (id: GridRowId) => row[id],
                }
                handleContextMenu(event, params)
              }
            }
          },
        },
      }}
      getRowId={(rows) => rows[idField] as GridRowId}
      autoHeight
      getRowHeight={() => "auto"}
      sx={{
        ...simpleGridStyle,
        ...sx,
        "& .MuiDataGrid-row": {
          cursor: "pointer",
        },
      }}
      disableColumnMenu={disableColumnMenu}
      disableColumnFilter={disableColumnFilter}
      disableColumnSelector={disableColumnSelection}
      disableDensitySelector={disableDensitySelector}
      disableSelectionOnClick={disableSelectionOnClick}
      initialState={{
        ...rest.initialState,
        pagination: initialPaginationPageSize
          ? {
              ...rest.initialState?.pagination,
              pageSize: initialPaginationPageSize,
            }
          : rest.initialState?.pagination,
      }}
      hideFooter={!rest.pagination}
      {...rest}
    />
  )
}
