import { useParams } from "react-router-dom"
import {
  Alert,
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material"
import { CollapsibleCard } from "components"
import { formatDate, DATE_FORMATS } from "utils/date"
import { StyledTableHeadCell } from "utils/tables"
import assert from "../../../../utils/assert"
import useDevice from "../../../../query-hooks/useDevice"

const DeviceDetails = () => {
  const { id } = useParams()
  assert(id)

  const { data, isError, error } = useDevice(id)

  return (
    <Grid item xs={12}>
      <CollapsibleCard title="Details" type="main" startExpanded hideCollapse>
        {isError && (
          <Alert severity="error" sx={{ mb: 4 }}>
            {`Could not load device details: ${error}`}
          </Alert>
        )}
        <TableContainer component={Card}>
          <Table>
            <TableBody>
              <TableRow>
                <StyledTableHeadCell>Device ID</StyledTableHeadCell>
                <TableCell>{data?.device_id}</TableCell>
              </TableRow>
              <TableRow>
                <StyledTableHeadCell>Device Platform</StyledTableHeadCell>
                <TableCell>{data?.device_platform}</TableCell>
              </TableRow>
              <TableRow>
                <StyledTableHeadCell>Native Device ID</StyledTableHeadCell>
                <TableCell>{data?.native_device_id}</TableCell>
              </TableRow>
              <TableRow>
                <StyledTableHeadCell>Device Reported Model</StyledTableHeadCell>
                <TableCell>{data?.device_reported_model}</TableCell>
              </TableRow>
              <TableRow>
                <StyledTableHeadCell>
                  Last Seen System Version
                </StyledTableHeadCell>
                <TableCell>{data?.last_seen_system_version}</TableCell>
              </TableRow>
              <TableRow>
                <StyledTableHeadCell>Last Seen App Version</StyledTableHeadCell>
                <TableCell>{data?.last_seen_app_version}</TableCell>
              </TableRow>
              <TableRow>
                <StyledTableHeadCell>Created At</StyledTableHeadCell>
                <TableCell>
                  {formatDate(
                    data?.created_at,
                    DATE_FORMATS.standardShortDateTime
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <StyledTableHeadCell>Updated St</StyledTableHeadCell>
                <TableCell>
                  {formatDate(
                    data?.updated_at,
                    DATE_FORMATS.standardShortDateTime
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <StyledTableHeadCell>Properties</StyledTableHeadCell>
                <TableCell>
                  <pre>{JSON.stringify(data?.properties, null, 2)}</pre>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </CollapsibleCard>
    </Grid>
  )
}

export default DeviceDetails
