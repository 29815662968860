import { useMutation } from "@tanstack/react-query"
import { endpoints } from "api"
import type { AxiosError } from "axios"
import { useNotification } from "context/notification"

export default function useCaisReportsGenerate() {
  const notify = useNotification()

  return useMutation(() => endpoints.caisReports.generate().get(), {
    onSuccess: () => {
      notify.info("A New CAIS Report is being generated")
    },
    onError: (e: AxiosError) => {
      const data = e?.response?.data as { message: string }
      notify.error(`Error: ${data?.message}`)
    },
  })
}
