/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Custom implementation of Node.js assert module without process dependency
 */

class AssertionError extends Error {
  actual: any
  expected: any
  operator: string
  generatedMessage: boolean
  code: string

  constructor(options: {
    message?: string
    actual?: any
    expected?: any
    operator?: string
    // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
    stackStartFn?: Function
  }) {
    const message =
      options.message ||
      `${options.actual} ${options.operator} ${options.expected}`

    super(message)
    this.name = "AssertionError"
    this.actual = options.actual
    this.expected = options.expected
    this.operator = options.operator || "not === expected"
    this.generatedMessage = !options.message
    this.code = "ERR_ASSERTION"

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, options.stackStartFn || this.constructor)
    }
  }
}

/**
 * Tests if value is truthy, throws if it's not
 */
function ok(value: any, message?: string): asserts value {
  if (!value) {
    throw new AssertionError({
      message: message,
      actual: value,
      expected: true,
      operator: "==",
      stackStartFn: ok,
    })
  }
}

/**
 * Deep equality comparison
 */
function deepEqual(actual: any, expected: any): boolean {
  if (actual === expected) {
    return true
  }

  if (
    actual === null ||
    expected === null ||
    typeof actual !== "object" ||
    typeof expected !== "object"
  ) {
    return false
  }

  if (Array.isArray(actual) && Array.isArray(expected)) {
    if (actual.length !== expected.length) return false

    for (let i = 0; i < actual.length; i++) {
      if (!deepEqual(actual[i], expected[i])) return false
    }

    return true
  }

  if (actual.constructor !== expected.constructor) return false

  const actualKeys = Object.keys(actual)
  const expectedKeys = Object.keys(expected)

  if (actualKeys.length !== expectedKeys.length) return false

  for (const key of actualKeys) {
    if (!Object.prototype.hasOwnProperty.call(expected, key)) return false
    if (!deepEqual(actual[key], expected[key])) return false
  }

  return true
}

/**
 * Tests for deep equality
 */
function deepStrictEqual<T>(
  actual: any,
  expected: T,
  message?: string
): asserts actual is T {
  if (!deepEqual(actual, expected)) {
    throw new AssertionError({
      message: message,
      actual: actual,
      expected: expected,
      operator: "deepStrictEqual",
      stackStartFn: deepStrictEqual,
    })
  }
}

/**
 * Tests shallow, coercive equality
 */
function equal(actual: any, expected: any, message?: string): void {
  if (actual != expected) {
    throw new AssertionError({
      message: message,
      actual: actual,
      expected: expected,
      operator: "==",
      stackStartFn: equal,
    })
  }
}

/**
 * Tests shallow, strict equality
 */
function strictEqual<T>(
  actual: any,
  expected: T,
  message?: string
): asserts actual is T {
  if (actual !== expected) {
    throw new AssertionError({
      message: message,
      actual: actual,
      expected: expected,
      operator: "===",
      stackStartFn: strictEqual,
    })
  }
}

/**
 * Tests for deep inequality
 */
function notDeepStrictEqual(
  actual: any,
  expected: any,
  message?: string
): void {
  if (deepEqual(actual, expected)) {
    throw new AssertionError({
      message: message,
      actual: actual,
      expected: expected,
      operator: "notDeepStrictEqual",
      stackStartFn: notDeepStrictEqual,
    })
  }
}

/**
 * Tests shallow, coercive inequality
 */
function notEqual(actual: any, expected: any, message?: string): void {
  if (actual == expected) {
    throw new AssertionError({
      message: message,
      actual: actual,
      expected: expected,
      operator: "!=",
      stackStartFn: notEqual,
    })
  }
}

/**
 * Tests shallow, strict inequality
 */
function notStrictEqual(actual: any, expected: any, message?: string): void {
  if (actual === expected) {
    throw new AssertionError({
      message: message,
      actual: actual,
      expected: expected,
      operator: "!==",
      stackStartFn: notStrictEqual,
    })
  }
}

/**
 * Tests if a function throws an error
 */
function throws(
  block: () => any,
  error?: (new (...args: any[]) => any) | RegExp | Error | Record<string, any>,
  message?: string
): void {
  let threw = false
  let actual: any

  try {
    actual = block()
  } catch (err) {
    threw = true
    actual = err
  }

  if (!threw) {
    throw new AssertionError({
      message: message || "Missing expected exception",
      actual: actual,
      expected: error,
      operator: "throws",
      stackStartFn: throws,
    })
  }

  if (error && typeof error !== "string") {
    if (error instanceof RegExp) {
      if (!error.test(String(actual))) {
        throw new AssertionError({
          message:
            message ||
            `The thrown error ${String(
              actual
            )} does not match the expected pattern ${error}`,
          actual: actual,
          expected: error,
          operator: "throws",
          stackStartFn: throws,
        })
      }
    } else if (error instanceof Error) {
      if (actual !== error) {
        throw new AssertionError({
          message:
            message ||
            `The thrown error is not identical to the expected error`,
          actual: actual,
          expected: error,
          operator: "throws",
          stackStartFn: throws,
        })
      }
    } else if (
      typeof error === "function" &&
      error.prototype instanceof Error
    ) {
      if (!(actual instanceof error)) {
        throw new AssertionError({
          message:
            message ||
            `The thrown error is not an instance of the expected constructor`,
          actual: actual,
          expected: error,
          operator: "throws",
          stackStartFn: throws,
        })
      }
    } else if (typeof error === "object" && error !== null) {
      const keys = Object.keys(error)
      for (const key of keys) {
        if (actual[key] !== error[key]) {
          throw new AssertionError({
            message:
              message ||
              `The thrown error does not match the expected properties`,
            actual: actual,
            expected: error,
            operator: "throws",
            stackStartFn: throws,
          })
        }
      }
    }
  }

  return actual
}

/**
 * Tests if value is falsy, throws if it's not
 */
function ifError(value: any): void {
  if (value) {
    let message = "ifError got unwanted exception: "

    if (typeof value === "object" && value !== null) {
      if (value.message) {
        message += value.message
      } else {
        try {
          message += JSON.stringify(value)
        } catch {
          // This is best-effort; if value can't be stringified, let it be
        }
      }
    } else {
      message += String(value)
    }

    throw new AssertionError({
      message: message,
      actual: value,
      expected: null,
      operator: "ifError",
      stackStartFn: ifError,
    })
  }
}

/**
 * Tests if string matches a regular expression
 */
function match(string: string, regexp: RegExp, message?: string): void {
  if (!regexp.test(string)) {
    throw new AssertionError({
      message: message,
      actual: string,
      expected: regexp,
      operator: "match",
      stackStartFn: match,
    })
  }
}

/**
 * Tests if string does not match a regular expression
 */
function doesNotMatch(string: string, regexp: RegExp, message?: string): void {
  if (regexp.test(string)) {
    throw new AssertionError({
      message: message,
      actual: string,
      expected: regexp,
      operator: "doesNotMatch",
      stackStartFn: doesNotMatch,
    })
  }
}

/**
 * Explicitly fails with a given message or default message
 */
function fail(message?: string): never
function fail(
  actual: any,
  expected: any,
  message?: string,
  operator?: string,
  stackStartFn?: () => void
): never
function fail(
  actual?: any,
  expected?: any,
  message?: string,
  operator?: string,
  stackStartFn?: () => void
): never {
  let msg: string
  if (arguments.length === 0) {
    msg = "Failed"
  } else if (
    arguments.length === 1 &&
    actual !== null &&
    typeof actual === "object"
  ) {
    // Support fail({ message: 'failure message' })
    const opts = actual as {
      message?: string
      actual?: any
      expected?: any
      operator?: string
      stackStartFn?: () => void
    }
    message = opts.message
    actual = opts.actual
    expected = opts.expected
    operator = opts.operator
    stackStartFn = opts.stackStartFn || fail
    msg = message || "Failed"
  } else if (typeof actual === "string") {
    // Support fail('failure message')
    msg = actual
    actual = undefined
    expected = undefined
  } else {
    msg = message || "Failed"
  }

  throw new AssertionError({
    message: msg,
    actual: actual,
    expected: expected,
    operator: operator || "fail",
    stackStartFn: stackStartFn || fail,
  })
}

// Interface
interface Assert {
  (value: any, message?: string): asserts value
  AssertionError: typeof AssertionError
  ok: typeof ok
  deepEqual: (actual: any, expected: any, message?: string) => void
  deepStrictEqual: typeof deepStrictEqual
  equal: typeof equal
  strictEqual: typeof strictEqual
  notDeepEqual: (actual: any, expected: any, message?: string) => void
  notDeepStrictEqual: typeof notDeepStrictEqual
  notEqual: typeof notEqual
  notStrictEqual: typeof notStrictEqual
  throws: typeof throws
  ifError: typeof ifError
  match: typeof match
  doesNotMatch: typeof doesNotMatch
  fail: typeof fail
  strict: Assert
}

// Create the main function with explicit type annotation
const assert: Assert = ok as Assert
// Add methods to the main function
assert.AssertionError = AssertionError
assert.ok = ok
assert.deepEqual = function (
  actual: any,
  expected: any,
  message?: string
): void {
  if (!deepEqual(actual, expected)) {
    throw new AssertionError({
      message: message,
      actual: actual,
      expected: expected,
      operator: "deepEqual",
      stackStartFn: assert.deepEqual,
    })
  }
}
assert.deepStrictEqual = deepStrictEqual
assert.equal = equal
assert.strictEqual = strictEqual
assert.notDeepEqual = function (
  actual: any,
  expected: any,
  message?: string
): void {
  if (deepEqual(actual, expected)) {
    throw new AssertionError({
      message: message,
      actual: actual,
      expected: expected,
      operator: "notDeepEqual",
      stackStartFn: assert.notDeepEqual,
    })
  }
}
assert.notDeepStrictEqual = notDeepStrictEqual
assert.notEqual = notEqual
assert.notStrictEqual = notStrictEqual
assert.throws = throws
assert.ifError = ifError
assert.match = match
assert.doesNotMatch = doesNotMatch
assert.fail = fail

// Strict mode
const strict = Object.assign(Object.create(assert), {
  equal: strictEqual,
  deepEqual: deepStrictEqual,
  notEqual: notStrictEqual,
  notDeepEqual: notDeepStrictEqual,
})

assert.strict = strict

export default assert
